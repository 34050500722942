@import "../../../sass/_var.scss";
@import "../../../sass/_mixins";

.swiper {
  margin-right: -14px !important;

  border: 1px solid transparent;
  padding-left: 20px !important;
  padding-right: 8px !important;
  margin-left: -20px !important;
  justify-content: center;

  @include breakpoint(max-sm) {
    width: 300px;
  }

  @media screen and (min-width: 1024px) {
    width: 874px;
  }

  @media screen and (min-width: 1200px) {
    width: 1180px;
    // padding-left: 20px !important;
    // margin-left: -30px !important;
    // margin-right: -10px !important;
  }

  // .swiper-wrapper {
  //   padding-left: 5px;
  // }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: #fff;
    box-shadow: 0px 2px 5px #ccccccee;
    height: calc(1.2vw + 26px);
    width: calc(1.2vw + 26px);
    border-radius: 50%;
    color: #898686;
    outline: none !important;
    transition: all 0.25s ease-out;
    top: 20%;

    &:after {
      font-size: 13px;
    }

    &.swiper-button-disabled {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($color: $theme-color, $amount: 5%);
      color: $grey-color;
    }
  }

  .swiper-button-next {
    right: 2px;
  }

  .swiper-button-prev {
    left: 8px;
  }

  .swiper-wrapper {
    .swiper-slide {

      // margin-left: 5px !important;
      // margin-right: 5px !important;
      .courseCard {
        //    @include breakpoint(md){
        //     width: 270px !important;
        //    }
      }
    }
  }
}

// .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after