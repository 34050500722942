@import "../../sass/var";

.sortBy {
  // margin-right: 1.1rem;
  cursor: pointer;

  &.mClass {
    margin-right: 1.1rem;
  }

  select {
    background-color: $background-color;
    padding: 0.65rem 2.3rem 0.65rem 1.3rem;
    border: none;
    font-size: 16px;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;
  }
}

.SelectBox {
  // margin-right: 1.1rem;
  cursor: pointer;

  select {
    background-color: $grey-color;
    color: $desc-color;
    padding: 0.65rem 2.3rem 0.65rem 1.3rem;
    border: none;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;
  }
}

.searchBox {
  position: relative;

  input {
    background-color: $grey-color !important;
    color: $desc-color;
    padding: 0.65rem 2.3rem 0.65rem 1.3rem;
    border: none;
    font-size: 14px;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;
    padding-left: 2.25rem;
  }

  svg {
    position: absolute;
    top: 13px;
    left: 10px;
  }
}