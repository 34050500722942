@import '../../sass/var';

.expired-webinar-p {
  font-size: 14px;
  font-weight: 400;
  color: $hover-dangerous-color;
  text-align: center;
  padding: 18px 0 25px;
  line-height: 1.4;
}

.webinar-search-view {
  .ais-SearchBox {
    display: block !important;

    .ais-SearchBox-form {
      margin-bottom: 0 !important;
    }
  }
}
