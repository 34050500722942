@import '/src/sass/var.scss';
@import '/src/sass/mixins.scss';

.userCard-body-details,
.single-course {
  img {
    width: auto;
  }
}

.icon-small {
  width: 15px;
  height: 15px;
}

.instractor-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.rating-number {
  font-size: 14px;
  color: #f2b636;
  padding-left: 8px;
  padding-right: 3.38px;
}

.num {
  font-size: 13px;
  padding-left: 5.29px;
}

.divider {
  width: 3px;
  height: 19px;
  background-color: #dbdbdb;
}

.lang-span {
  font-size: 13px;
  padding-left: 13px;
}

.lang {
  padding-left: 13px;
}

.enrolled {
  padding-left: 8px;
  font-size: 13px;
  padding-top: 9px;
}

.lang-image {
  margin-bottom: 6px;
}

.learn-section-container {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  margin-bottom: 50px;
}

.learn-section-title {
  font-size: 18px;
  font-weight: 500;
  padding-top: 21px;
  padding-bottom: 20px;
  padding-left: 31px;
}

.learn-section-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.learn-section-item {
  width: 43%;
  flex: 0 0 auto;
  padding-bottom: 10px;
  font-size: 14px;
}

.learn-section-icon {
  padding-right: 15px;

  margin-bottom: 20px;
}

.learn-section-wraper {
  display: flex;
}

.requirements-title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 19px;
}

.requirements-body {
  display: flex;
}

.requirements-section-icon {
  padding-right: 15px;
}

.RequirementsSection {
  padding-bottom: 50px;
}

.course-content-headline {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 16px;
}

.course-content-disc {
  font-size: 14px;
  padding-bottom: 20px;
}

.course-content-list {
  list-style-type: none;
  margin-bottom: 0px !important;
  margin-top: 15px;
  padding-left: 6px !important;
}

.course-content-list-item {
  display: flex;
  list-style-type: none;
  background: #f3f3f4;
  border: 1px solid #f1f1f1;
  border-radius: 5px;

  height: 59px;
  margin-bottom: 15px;
  justify-content: space-between;
  line-height: 59px;
}

.course-content-list-item-title {
  padding-left: 22px;
}

.course-content-list-item-timer {
  padding-right: 22px;
  font-size: 15px;
}

.loading-width {
  width: 60%;
}

.course-content-list-title-icon {
  width: 18px;
  height: 18px;
  background-color: #7e59d1;
  border-radius: 50%, 50%;

  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: -3px;
}

.course-content-list-title-text {
  margin-left: 8px;
  font-size: 15px;
  font-weight: 500;
}

.course-content-list-title-icon-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.what-will-learn-section {
  @media screen and (min-width: 1024px) {
    .swiper {
      width: 580px;
    }
  }

  @media screen and (min-width: 1200px) {
    .swiper {
      width: 792px;
    }

    .mainCard {
      min-height: 378px;
    }
  }
}

.what-will-learn-section-headline {
  font-size: 20px;
  font-weight: 500;
  padding-top: 34px;
  margin-bottom: 20px;
}

.icon1 {
  padding-right: 8px;

  @include breakpoint(max-xl) {
    padding-right: 2px;
  }
}

.span1 {
  padding-right: 17px;
  font-size: 12px;
  font-weight: 500;

  @include breakpoint(max-xl) {
    font-size: 11px;
  }
}

.cartsidebar {
  @include breakpoint(max-xl) {
    width: 260px;
    margin-left: 10px;

    .bookingDetails {
      .bookingDetails-progress {
        p {
          font-size: 13px;
        }
      }
    }

    .cartsidebar-enrolled-on {
      font-size: 13px;
    }

    .cartsidebar-li-divider,
    .cartsidebar-divider {
      width: 211px;
    }

    .cartsidebar-video {
      width: 290px;
    }
  }

  .cartsidebar-go-to-course-ptn {
    font-size: 14px;
  }

  width: 340px;
  z-index: 1000;
  position: absolute;
  margin-left: 30px;
  margin-bottom: 60px;
}

.cartsidebar-video {
  width: 340px;
  border-radius: 8px 8px 0px 0px;
  height: 200px;
  object-fit: cover;
}

.videoLoading {
  // width: 340px;
  border-radius: 8px 8px 0px 0px;
  height: 160px;
  background-color: rgb(207, 207, 207);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .cartsidebar-video {
    width: 100%;
  }
  .playVideo {
    position: absolute;
    width: 100%;
    top: 30%;
  }
}

.cartsidebar-body {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff;
  // width: 340px;
  padding-left: 24px;
  padding-right: 24px;
  background: #ffffff;

  &.course-card-bar {
    button {
      height: 40px;
      font-size: 16px;
      border-radius: 6px;
      transition: all 0.5s ease;
    }
  }
}

.cartsidebar-body {
  padding-top: 30px;
}

.cartsidebar-body-disconted {
  font-size: 22px;
  font-weight: 600;
  padding-right: 13.76px;
  padding-bottom: 19px;
}

.cartsidebar-body-prise {
  font-size: 16px;
  text-decoration: line-through;
  color: #6a6f73;
}

.cartsidebar-add-ptn {
  width: 292px;
  height: 40px;
  background: #7e59d1;
  border-radius: 6px;
  border: none !important;
  color: white;
  font-size: 16px;
  font-weight: 500;

  @include breakpoint(max-xl) {
    width: 100%;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.cartsidebar-footer {
  padding: 1rem 0;
}

.cartsidebar-go-to-course-ptn {
  width: 100%;
  height: 45px;
  background: #efeff6;
  border-radius: 6px;
  border: none !important;
  color: #7e59d1;
  font-size: 16px;
  font-weight: 500;
}

.cartsidebar-course-includes {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}

.cartsidebar-ul {
  list-style-type: none;
  margin-bottom: 10px;
}

.cartsidebar-li-items {
  color: #6a6f73;
  font-size: 14px;

  @include breakpoint(max-xl) {
    font-size: 13px;
  }
}

.word {
  padding-left: 9px;
}

.cartsidebar-li-divider {
  width: 100%;
  height: 1px;
  background-color: $divider-line-color;
}

.cartsidebar-li {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}

.cartsidebar-divider {
  width: 292px;
  height: 1px;
  background: #cecece;
  margin-top: 28px;
}

.cartsidebar-social-icon {
  padding-right: 33px;
  padding-top: 26px;
  padding-bottom: 37px;
}

.cartsidebar-sosial {
  padding-left: 31px;
}

.student-feedback-body {
  display: flex;
}

.student-feedback-card {
  background: #ffffff;
  box-shadow: 0px 3px 8px #65656529;
  border-radius: 6px;
  width: 20%;
  height: 147px;

  @include breakpoint(max-xl) {
    width: 25%;
  }
}

.student-feedback-card-num {
  font-size: 42px;
  text-align: center;
  padding-bottom: 3px;
  padding-top: 12px;
}

.student-feedback-card-disc {
  font-size: 10px;
  text-align: center;
  padding-bottom: 23px;
  color: #6a6f73;
}

.student-feedback-card-stars {
  text-align: center;
  padding-bottom: 9px;

  @include breakpoint(max-xl) {
    .ant-rate-star:not(:last-child) {
      margin-right: 4px;
    }

    .anticon svg {
      display: inline-block;
      width: 15px;
    }
  }
}

.ant-collapse-arrow svg {
  color: #fff;
  font-size: 11px;
  margin-top: 3.4px;
}

.student-feedback-rating {
  padding-left: 24px;
  display: flex;
}

.reviewings-headline-headline {
  padding-top: 10px;
}

.reviewings-ul {
  list-style: none;
  max-height: 440px;
  overflow: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $background-color;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #7e59d1;
    border-radius: 6px;
  }
}

li {
  .reviewings-li {
    display: flex;
    padding-top: 21px;
    border-bottom: 1px solid $hover-color;
  }

  &:last-child {
    .reviewings-li {
      border-bottom: none;
    }
  }
}

.reviewings-li-disc {
  padding-left: 28px;
}

.reviewings-li-disc-name {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 6.67px;
}

.reviewings-li-disc-comment {
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 15px;
}

.reviewings-li-disc-rating-time {
  color: #6a6f73;
  font-size: 13px;
  padding-left: 7px;
}

.reviewings-li-disc-rating-stars {
  padding-right: 4.61px;
}

.review-divider {
  width: 100%;
  height: 1px;
  background: #f1f1f1;
}

.reviewings-comments-show-more {
  color: #7e59d1;
  font-weight: 500;
  font-size: 14px;
  padding-right: 10.25px;
  padding-top: 28px;
  cursor: pointer;
}

.reviewings-show {
  padding-bottom: 30px;
}

.course-content-list-item-expand {
  // border: 1px solid #f1f1f1;
  // border-radius: 0px 0px 5px 5px;
  padding-bottom: 15px;
}

.course-content-list-item-expand-li {
  display: flex;
  list-style-type: none;
  margin-bottom: 17px;
  justify-content: space-between;

  @include breakpoint(max-xl) {
    margin-bottom: 1.5rem;
  }
}

.collapse show course-content-list-item-expand {
  height: 100%;
}

.course-content-list-item-expand-li-leftside {
  width: 64%;
  display: flex;
  align-items: start;

  @include breakpoint(max-xl) {
    width: 45%;
  }
}

.course-content-list-item-expand-span {
  color: #6a6f73;
  font-size: 13px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 8px;
}

.course-content-list-item-expand-span-enrolled {
  color: #6a6f73;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  @include breakpoint(max-xl) {
    font-size: 12px;
  }
}

.arrow-down {
  cursor: pointer;
}

.extra {
  color: #6a6f73;
  font-size: 12px;
  padding-bottom: 10px;
  padding-top: 10px;

  @include breakpoint(max-xl) {
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

.course-content-list-item-expand-li-time {
  color: #2a2a2a;
  font-size: 12px;

  .unlockedDate {
    margin-right: 0.2rem;
  }

  .unlockedTime {
    margin-left: 0.2rem;
  }

  .unlockedDate,
  .unlockedTime {
    svg {
      margin-right: 0.25rem;
      width: 12px;
    }
  }

  @include breakpoint(max-xl) {
    padding-left: 5px;
  }
}

.progress {
  width: 392px;
  height: 5px;
  margin-left: 13px;
  margin-top: 10px;
}

.progress-bar {
  width: 60%;
  background-color: #7e59d1;
}

.star-badding {
  padding-right: 4.74px;
}

.percentage {
  color: #2a2a2a;
  font-size: 14px;
  padding-left: 18px;
}

.cartsidebar-price {
  display: flex;
  align-items: center;

  &.with-icon {
    justify-content: space-between;
    align-items: center;
  }
}

.cartsidebar-heart {
  padding-right: 20px;
  padding-right: 0;
  cursor: pointer;

  .card-favourite-button {
    display: inline-flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 6px #cbc3dc;

    svg {
      width: 18px;
    }

    @-webkit-keyframes mover {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(-10px);
      }
    }

    @keyframes mover {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(-10px);
      }
    }

    &:hover {
      -webkit-animation: mover 0.7s infinite alternate;
      animation: mover 0.7s infinite alternate;
    }
  }
}

.ant-collapse {
  border: none !important;
  background-color: #ffffff !important;
}

.ant-collapse-item {
  border-bottom: none !important;
  border: 1px solid #f1f1f1;
  border-radius: 5px 5px 0px 0px;
  background-color: #f3f3f4 !important;
  margin-bottom: 15px;
}

.ant-collapse-arrow {
  width: 18px;
  height: 18px;
  background: #7e59d1 0% 0% no-repeat padding-box;
  border-radius: 50%;
  position: relative;
  vertical-align: 3px !important;
}

// background: #FFFFFF 0% 0% no-repeat padding-box;
// border: 1px solid #F1F1F1;
// border-radius: 0px 0px 5px 5px;
.ant-collapse-content {
  border-top: none !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 0px 0px 5px 5px;
}

.ant-collapse-header {
  color: #2a2a2a !important;
  font-size: 15px;
  font-weight: 500;
}

.ant-collapse-extra {
  color: #2a2a2a;
  font-size: 15px;
}

.loadingAvatar {
  padding-right: 18px;
}

.firstAvatar {
  padding-left: 17px;
}

.cartsidebar-ul li:last-child .cartsidebar-li-divider {
  display: none;
}

.course-content-container {
  display: flex;
  justify-content: space-between;
}

.expand-all {
  display: inline-block;
  margin-right: 5px;
}

.expand-color {
  background-color: #7e59d1;
  border: none;

  &:hover {
    background-color: #4b20d0 !important;
  }

  &:active {
    background-color: #4b20d0;
    background: #4b20d0;
  }
}

.ant-back-top {
  right: 50px;
  bottom: 50px;
  z-index: 1000;
}

.expand-arrow {
  height: 30px;
  cursor: pointer;
}

.overLayer {
  background-color: #fff;
  box-shadow: 0px 0px 6px #cbc3dc66;
  border-radius: 2px;
  padding: 20px 25px;
  margin-top: -20px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-size: 14px;
    color: $desc-color;
    text-transform: capitalize;
  }

  h6 {
    font-size: 14px;
    margin-top: 0.25rem;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 0;
  }

  &.backgroundGradient {
    background: linear-gradient(to right, #573b94, #7e59d1 50%);
    padding: 25px 28px;

    .oneOnOneHeader {
      margin-bottom: 0.75rem;

      h5 {
        font-size: 19px;
        color: #fff;
        font-weight: 400;
        margin-bottom: 0;
      }

      .oneOnone-price {
        color: #fff;
        font-size: 12px;

        svg {
          margin-right: 0.5rem;
          // margin-bottom: 3px
        }

        span {
          margin-right: 0.1rem;
          font-size: 14px;
          color: #fff;

          &.perHour {
            opacity: 0.85;
            font-size: 12px;
          }
        }
      }
    }

    .oneOnOneBody {
      p {
        font-size: 14px;
        color: #fff;
        opacity: 0.92;
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .whiteBtn {
      padding: 8px 19px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include breakpoint(max-xl) {
    margin-top: -60px;

    .col-xl-3.col-lg-4.d-flex.align-items-center.justify-content-center {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    &.backgroundGradient {
      padding: 18px 0px 24px 34px;

      .oneOnOneHeader {
        width: 100%;

        h5 {
          font-size: 16px;
        }

        .oneOnone-price {
          svg {
            width: 16px;
            margin-right: 0.25rem;
            margin-left: 0.5rem;
          }
        }
      }

      .whiteBtn {
        padding: 8px 12px;
      }
    }

    padding: 20px 20px 20px 10px;
  }
}

.datesAvailability {
  margin-top: 2rem;

  h4 {
    margin-bottom: 1rem;
    font-size: 20px;
  }

  .datesAvailability-container {
    // box-shadow: 0px 0px 6px #CBC3DC66;
    border: 1px solid lighten($color: $border-color, $amount: 10%);

    .availability-item {
      padding: 5px 15px;
    }

    .row {
      font-size: 15px;
      border-bottom: 1px solid lighten($color: $border-color, $amount: 10%);
      padding: 13px 0.5rem;

      &:last-child {
        border: none;
      }

      .separator {
        color: lighten($color: $separator-color, $amount: 20%);
        font-size: 18px;
      }
    }

    .ant-radio-group {
      width: 100%;
    }

    .disabled {
      color: lighten($color: $separator-color, $amount: 18%);

      background-color: lighten($color: $separator-color, $amount: 40%);

      .ant-radio-wrapper {
        color: lighten($color: $separator-color, $amount: 18%);
      }
    }

    span.ant-radio + span {
      width: 100%;
    }
  }
}

.bookingDetails {
  .bookingDetails-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-color;
    margin: -31px -25px 0 -25px;
    padding: 6px 0;
    color: $theme-color;

    .ant-progress-inner {
      width: 17px !important;
      height: 17px !important;
      font-size: 0 !important;
      margin-right: 0.5rem;
      margin-top: -2px;

      svg {
        .ant-progress-circle-path {
          stroke: $theme-color;
        }
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .bookingDetails-countdown {
    .countdown-body {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.25rem;
      margin-top: 0.5rem;
      background-color: lighten($color: $grey-color, $amount: 2.2%);
      margin: 0 -25px 15px -25px;
      padding: 8px 30px 5px;

      .time-unit {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: -25px;
          top: 12%;
          width: 1px;
          height: 76%;
          background-color: $hover-color;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        h6 {
          font-size: 18px;
          font-weight: 600;
          color: $title-color;
          margin-bottom: 0;

          &:after {
            display: none;
          }
        }

        span {
          small {
            font-size: 13px !important;
            color: $desc-color;
          }
        }
      }
    }
  }
}

.selectBundle {
  .modal-body {
    padding: 0 2rem 2rem;

    .datesAvailability {
      margin-top: 0;
    }

    .btn-cont {
      width: 100%;
      text-align: center;

      button {
        margin-top: 1.5rem;
        width: 60%;
        padding: 11px 0;
      }
    }

    .availableBundles {
      display: block;
      margin-top: 1rem;

      @include breakpoint(lg) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.requestBundle {
  .modal-dialog {
    @include breakpoint(lg) {
      max-width: 480px;

      .modal-header {
        border: none;
      }

      .modal-body {
        padding: 2rem;
        padding-top: 0;
      }
    }
  }
}

.courseBrief {
  .modal-header {
    position: absolute;
    top: calc(0px - 12vh);
    right: calc(500px - 50vw);
    display: none;

    .btn-close {
      font-size: 30px;
    }
  }

  .modal-body {
    padding: 0;

    .vjs-big-play-centered {
      .vjs-big-play-button {
        background-color: $theme-color;
      }
    }

    .vjs-has-started,
    .vjs-audio-only-mode {
      .vjs-control-bar {
        background-color: $theme-color;
      }
    }

    .video-js {
      .vjs-slider {
        background-color: #aaa;

        .vjs-load-progress {
          div {
            background-color: #c7c7c7bf;
          }
        }
      }
    }
  }
}

.vjs-big-play-centered {
  .vjs-big-play-button {
    background-color: $theme-color;
  }
}

.vjs-has-started,
.vjs-audio-only-mode {
  .vjs-control-bar {
    background-color: $theme-color;
  }
}

.video-js {
  .vjs-slider {
    background-color: #aaa;

    .vjs-load-progress {
      div {
        background-color: #c7c7c7bf;
      }
    }
  }
}

.vjs-big-play-centered {
  .vjs-big-play-button {
    background-color: $theme-color;
  }
}

.vjs-has-started,
.vjs-audio-only-mode {
  .vjs-control-bar {
    background-color: $theme-color;
  }
}

.video-js {
  .vjs-slider {
    background-color: #aaa;

    .vjs-load-progress {
      div {
        background-color: #c7c7c7bf;
      }
    }
  }
}

.free-course {
  font-size: 22px;
  font-weight: 600;
}

.student-feedback-card-stars-block {
  display: block;
  padding-left: 25px;
  padding-right: 25px;
}

.progress-container {
  width: 48%;
}

.ant-progress-line {
  padding-bottom: 9px;
}

.ant-progress-inner {
  background-color: #e0e0f6;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: #7e59d1;
}

.add-review-btn {
  margin-bottom: 60px;
  width: 24%;
  height: 40px;
  border-radius: 4px;
}

.Write-review {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
}

.Write-review-rate {
  font-size: 31px;
  padding-bottom: 16px;
}

.Write-review-textarea {
  margin-bottom: 40px;
  background-color: rgb(239, 239, 246);
  border-radius: 8px;
  border: none;
}

textarea.ant-input {
  padding: 20px !important;
  color: #676767 !important;
  font-size: 15px !important;
}

.learn-section-text {
  font-size: 14px;
  line-height: 1.6;

  @include breakpoint(max-xl) {
    font-size: 13px;
  }
}

.cartsidebar-enrolled-on {
  color: #6a6f73;
  font-size: 14px;
  padding-left: 8.9px;
}

.cartsidebar-enrolled-on-date {
  color: #7e59d1;
}

.ant-form-item.full-width {
  .ant-form-item-row {
    display: block;
  }
}

.course-content {
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          span {
            justify-content: center;
            svg {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
