.course-grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  min-height: 70vh;
  .mainCard {
    height: fit-content;
    // max-width: 300px;
  }
  .card {
    margin-bottom: 0;
  }
  .grid-full-width {
    grid-column: span 5;
  }
}
.filter-row-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h4 {
    color: #2a2a2a;
    font-size: 24px;
    font-weight: 500;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 123px;
    height: 40px;
    background-color: #efeff6;
    border-radius: 4px;
    border: 0;
    box-shadow: none !important;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      color: #6a6f73;
      font-size: 16px;
    }
  }
}

.ais-Hits-item {
  .mainCard {
    margin-bottom: 0;
  }
  &:empty {
    display: none;
  }
}

ul.ais-Hits-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  min-height: 70vh;

  .ais-Highlight {
    .ais-Highlight-highlighted {
      background: #c7c7ca;
    }
  }
}

.search-view {
  .ais-SearchBox {
    display: none;
  }
  form.ais-SearchBox-form {
    margin-bottom: 20px;
  }

  .pagination {
    display: flex;
    justify-content: center;

    .ais-Pagination {
      margin-top: 20px;

      .ais-Pagination-list {
        display: flex;

        .ais-Pagination-item {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 2px;
          border-radius: 50%;

          .ais-Pagination-link {
            color: #000;
          }

          &.ais-Pagination-item--firstPage,
          &.ais-Pagination-item--previousPage {
            margin-right: 10px;
          }

          &.ais-Pagination-item--nextPage {
            margin-left: 10px;
          }

          &.ais-Pagination-item--previousPage,
          &.ais-Pagination-item--firstPage,
          &.ais-Pagination-item--nextPage {
            width: auto;
            height: auto;
          }

          &.ais-Pagination-item--selected {
            background-color: #7e59d1;

            .ais-Pagination-link {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.ais-RefinementList {
  .ais-RefinementList-list {
    .ais-RefinementList-item {
      .ais-RefinementList-label {
        display: flex;
        align-items: center;

        .ais-RefinementList-labelText {
          font-size: 13px;
          color: #6a6f73;
          display: inline-block;
          margin: 0 11px;
        }

        .ais-RefinementList-count {
          color: #6a6f73;
          font-size: 13px;
          position: relative;

          &::before {
            content: '(';
            position: absolute;
            left: -6px;
          }

          &::after {
            content: ')';
            position: absolute;
            right: -6px;
          }
        }
      }
    }
  }
}
