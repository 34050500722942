.notifications-menu-wrapper {
  width: 34rem;
  max-width: 100%;
  max-height: 65vh;
  overflow: auto;
  padding-bottom: 0 !important;
  .notifications-footer-holder {
    position: sticky !important;
    bottom: 0 !important;
    background-color: #fff !important;
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 55px;
      a {
        font-size: 14px;
        color: #7e59d1 !important;
        padding: 0 12px;
      }
    }
  }
  .ant-dropdown-menu {
    padding: 0;
    > li {
      // &:first-of-type {
      //   height: 30px;
      // }
      // &:last-of-type {
      //   height: 30px;
      // }
    }
  }
  li {
    background-color: unset !important;
  }

  .menu-msg {
    padding: 2rem 0;
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: 1px;
    text-transform: capitalize;
    text-align: center;
  }
  .menu-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7e59d1 !important;
    font-size: 14px;
    text-decoration: underline;
    padding: 8px 12px;
  }
  .notify-time {
    font-size: 12px;
    color: gray;
  }
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
  .menu-title {
    padding: 8px 12px;
    border-bottom: 1px solid #cecece;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.2;
    text-transform: capitalize;
  }
  .menu-item {
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 5px 12px;
    // padding-bottom: 0.5rem;
    border-bottom: 1px solid #cecece;
    &.newNotification {
      background-color: rgba(126, 89, 209, 0.04) !important;
    }
    // &:last-of-type {
    // }
    .image-wrapper {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-inline-end: 1rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      font-size: 1.6rem;
      line-height: 1.2;
      text-transform: capitalize;
      max-inline-size: 25ch;

      .title {
      }

      .sub-title {
        font-size: 1.4rem;
        color: #000;
        font-weight: 500;
        b {
          font-weight: 500;
          color: #000;
          // display: block;
        }
      }
    }
  }
}
