@import '/src//sass/_var';
@import '/src//sass/_mixins';

.recommended-courses-wrapper {
  padding-bottom: 60px;
  .recommended-courses__title {
    font-size: 22px;
    font-weight: 500;
    color: #2a2a2a;
    margin-bottom: 30px;
  }
}
.checkout-layout-wrapper {
  display: flex;
  gap: 30px;
  margin: auto auto 5rem;
}
.shoppingCart {
  .shopping-cart-Items {
    .noItemsInCart {
      margin: 7rem auto 10rem;
      text-align: center;

      svg,
      p {
        margin-bottom: 1.5rem;
      }

      p {
        font-size: 18px;
        color: $title-color;
        font-weight: 500;
      }
    }

    .recommendedCourses {
      h5 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 1.8rem;
      }
    }
  }

  .cartItems,
  .order-summary,
  .checkoutInformation {
    border: 1px solid $hover-color;
    padding: 1.5rem 1.25rem;
    border-radius: 6px;
    height: fit-content;
  }

  .cartItems {
    flex: 1;
    & > h6 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }

  .order-summary {
    width: 370px;
    margin-bottom: 3rem;
    & > h5 {
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }

    .add-coupon-discount {
      margin-bottom: 2rem;
      padding-bottom: 1.5rem;
      border-bottom: 0.13rem solid $hover-color;

      .coupon-discount-title {
        display: block;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        letter-spacing: 0.64px;
        color: #6a6f73;
      }

      .ant-input-group {
        display: flex;
        margin-bottom: 1rem;

        input.ant-input {
          color: $desc-color;
          border-color: $hover-color;

          &:focus {
            border-color: $theme-color;
            box-shadow: none;
          }
        }
      }

      .ant-checkbox + span {
        font-weight: 600;
      }

      .ant-btn-primary {
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 4px 4px 0px;
        background-color: $secondary-color !important;
        border-color: $secondary-color !important;

        // &:hover {
        //   border: 1px solid darken($color: $secondary-color, $amount: 10%);
        // }
      }
    }

    .price-calc {
      margin-bottom: 10px;
      padding-bottom: 15px;
      border-bottom: 0.13rem solid $hover-color;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .calc-item {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 14px;
          font-weight: 500;
          color: #393e46;
        }
      }
    }

    .total-price {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 25px;
      color: #393e46;
    }

    .terms-privacy-agreeing {
      font-size: 11px;
      color: $desc-color;
      margin-top: 0.75rem;
      line-height: 22px;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @include breakpoint(max-xl) {
    .order-summary {
      padding: 1.5rem 1rem;

      .add-coupon-discount {
        .ant-checkbox + span {
          font-size: 11px;
        }
      }

      .price-calc {
        span {
          font-size: 13px;
          margin-bottom: 8px;
        }
      }

      // .total-price {
      //   span {
      //     font-size: 16px;
      //   }
      // }
    }
  }
}

.checkout {
  .checkoutInformation {
    h4 {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
    }

    label {
      margin-bottom: 0.5rem;
      font-size: 15px;

      // font-weight: 500;
      &.ant-radio-wrapper {
        width: 100%;
        margin: 5px;
      }
    }

    .ant-select-selector {
      height: 44px;
      border-radius: 4px;
      border: 1px solid darken($color: $hover-color, $amount: 5%);

      .ant-select-selection-item {
        line-height: 44px !important;
        // font-weight: 500;
        font-size: 16px;
      }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $border-color;
    }
  }
  .paymentMethods {
    flex: 1;
    .paymentItem {
      border: 1px solid $hover-color;
      padding: 5px;
      border-radius: 4px;
    }
  }
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  // box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.payment-form {
  box-shadow: none;
  border: 1px solid var(--sail-color-gray-100);
  width: 380px;
  padding-top: 20px;
  margin: auto;
}

.checkout-btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #7e59d1;
  color: white;
  font-size: 16px;

  &:hover {
    background: #7e59d1;
    color: white;
    border: none !important;
  }
}

.payment-stripe-form {
  #root {
    display: flex;
    align-items: center;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  }

  form {
    width: 100%;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    margin-bottom: 16px;
    height: auto;
    padding: 0;
  }
  button {
    margin-top: 18px;
  }
  /* Buttons and links */
  // button {
  //   background: #7e59d1;
  //   color: #ffffff;
  //   border-radius: 4px;
  //   border: 0;
  //   padding: 12px 16px;
  //   font-size: 16px;
  //   font-weight: 600;
  //   cursor: pointer;
  //   display: block;
  //   transition: all 0.2s ease;
  //   box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  //   width: 100%;
  //   margin-top: 218px;
  // }

  // button:hover {
  //   filter: contrast(115%);
  // }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
}
