@import '../../sass/_var.scss';
@import '../../sass/mixins';

.status-other {
  color: $theme-color;
}

.status-closed {
  color: $primary-color;
  display: inline-block;
  margin: 15px 0;
  font-size: 12px;
}

.mainCard {
  border: none;
  // box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000019, 0px 0px 6px #00000011;
  border-radius: 8px;
  min-height: 360px;

  &.zoomMeeting {
    min-height: 385px;
  }

  &:hover {
    .add-to-cart-btn,
    .live-session-counter {
      opacity: 1;
      bottom: -3px;
      padding-left: 10px;
    }

    //  {
    //     visibility: visible;
    // }

    // .card-img-top {
    //     transform: scale(1.05);
    // }
  }

  .card-img {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    position: relative;
    min-height: 130px;
    a {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .webinarStatus {
      position: absolute;
      width: 100%;
      top: 15px;
      padding: 0 15px;

      span {
        background-color: #fff;
        border-radius: 15px;
        padding: 3px 12px;
        font-size: 12px;
        font-weight: 500;

        &.happeningNow {
          background-color: $primary-color;
          color: #fff;

          svg {
            margin-right: 0.15rem;
            margin-top: -3px;
          }
        }

        &.comingSoon {
          background-color: $trinary-color;
          color: #fff;
        }

        &.completed {
          background-color: $secondary-color;
          color: #fff;
        }
      }
    }

    .card-img-top {
      border-radius: 8px 8px 0 0;
      height: 130px;
      width: 100%;
      object-fit: cover;
      transition: all 0.25s ease-in;
    }
  }

  .card-label-top {
    position: absolute;
    color: #fff;
    top: 0.75rem;
    left: 0.75rem;
    font-weight: 300;
    font-size: 14px;
    padding: 5px 15px;
    background-color: $secondary-color;

    &.advanced {
      background-color: $primary-color;
    }

    &.beginner {
      background-color: $secondary-color;
    }

    &.intermediate {
      background-color: $trinary-color;
    }
  }

  .card-course-body {
    padding: 0.7rem 1rem 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .webinarTime {
      position: absolute;
      top: -20px;
      right: 12px;
      z-index: 1;
      background-color: $grey-color;
      //   padding: 5px 10px;
      height: 43px;
      width: 43px;
      color: $theme-color;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        display: block;
        text-align: center;
        line-height: 17px;

        &:first-child {
          font-weight: 500;
          font-size: 13px;
        }

        &:last-child {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .card-instructor-name {
      font-size: 12px;
      font-weight: 500;
      color: $theme-color;

      a {
        color: $theme-color;
      }
    }

    .card-favourite-button {
      line-height: 1rem;
      cursor: pointer;

      svg {
        width: 22px;
        height: 22px;
      }

      i {
        font-size: 20px;
        font-weight: 500;
      }
    }

    a {
      text-decoration: none;
      color: $title-color;
      transition: all 0.25s ease-out;

      .card-course-title {
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 5px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // display: block;
        // min-height: 48px;
      }

      &:hover {
        color: lighten($title-color, 10%);
        text-decoration: underline;
      }
    }

    .afterSale {
      font-size: 16px;
    }

    .beforeSale {
      small {
        font-size: 11px;
        text-decoration: line-through;
      }
    }
  }
}

.topInstructors {
  margin: 1rem auto 0.25rem;

  .instructorCards {
    a {
      text-decoration: none;

      .card {
        .card-header {
          border-bottom: none;
          background-color: transparent;
          padding: 0;

          .bg-image {
            width: 100%;
            // height: 125px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 110px;
              height: 110px;
              object-fit: cover;
              border-radius: 50%;
              transition: all 0.25s ease-in;
              margin: 1rem auto auto;
            }
          }
        }

        .card-body {
          .card-title {
            font-size: 16px !important;
          }

          .card-text {
            color: $title-color;
            font-weight: 300;
            font-size: 14px;
          }
        }

        .card-footer {
          background-color: transparent;
          border-top: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 11px;

          svg {
            margin-right: 0.25rem;
            fill: $desc-color;
          }
        }
      }

      &:hover {
        .bg-image {
          img {
            transform: scale(1.05) translateY(-3px);
          }
        }
      }
    }
  }
}

.swiper-lazy {
  display: none;
}

.swiper-lazy.swiper-lazy-loaded {
  display: block;
}

.swiper-lazy-preloader {
  width: 100%;
  height: 126px;
  object-fit: contain;
  transform-origin: 0;
  box-sizing: border-box;
  border: 4px solid transparent;
  border-radius: 0;
  border-top-color: transparent;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  margin-left: 0;
  margin-top: 0;
  background-color: transparent;
  border-width: 0;

  .skeleton-wrapper {
    padding: 3px 15px;
  }
}

.gift-btn {
  width: 35px;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #d6d1e5d6;
  opacity: 1;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.add-to-cart-btn {
  position: absolute;
  bottom: -3px;
  right: 0;
  border-radius: 0px !important;
  width: 146px;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  opacity: 0;
  transition: all 0.15s ease-in;
  font-size: 15px;
  background-color: #7e59d1;
  height: 42px;
  text-align: center;
  line-height: 42px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

.live-icon {
  position: absolute;
  width: 100%;
  top: 15px;
  padding: 0 15px;

  img {
    width: 50px;
    height: 20px;
  }
}

.bookingPercentage {
  background-color: #fff;
  padding: 2px 15px;
  border-radius: 15px;
  font-size: 12px;
  right: 15px;
  position: absolute;
  transition: all 0.15s ease-in;

  &.fullBooked {
    color: $primary-color;
  }

  &.onBooking {
    color: $theme-color;
  }
}

.live-session-counter {
  background: #f4f5f9;
  position: absolute;
  // margin-left: 12pz;
  bottom: -40px;
  left: 0;
  opacity: 0;
  width: 138px;
  width: 170px;
  clip-path: polygon(0 0, 100% 0, 89% 100%, 0% 100%);
  // visibility: hidden;
  transition: all 0.15s ease-in;

  .card-course-text {
    padding: 3px 1px 4px;
  }
}

.time-unit {
  padding: 0.25rem 0 0 0.5rem;
  text-align: center;

  h6 {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-bottom: -12px;
    letter-spacing: 1px;
    color: #7e59d1;

    &:first-child {
      font-weight: 500;
      font-size: 13px;
    }

    &:last-child {
      font-weight: 400;
      font-size: 12px;
    }
  }

  & > .d-flex {
    min-height: 20px;
  }

  .card-instructor-name {
    font-size: 12px;
    font-weight: 500;
    color: $theme-color;
  }

  .card-favourite-button {
    line-height: 1rem;

    i {
      font-size: 20px;
      font-weight: 500;
    }
  }

  a {
    text-decoration: none;
    color: $title-color;
    transition: all 0.25s ease-out;

    // .card-course-title {
    //   font-size: 15px;
    //   font-weight: 500;
    //   line-height: 24px;
    //   margin-bottom: 5px;
    //   // overflow: hidden;
    //   // white-space: nowrap;
    //   // text-overflow: ellipsis;
    //   // display: block;
    // }

    &:hover {
      color: lighten($title-color, 10%);
      text-decoration: underline;
    }
  }
}

.card-course-text {
  font-size: 13px;
  font-weight: 400;

  p {
    // min-height: 42px;
    margin-bottom: 0rem;
  }

  & > a {
    text-decoration: none;
  }

  span.meetingTimeInterval {
    font-size: 12px;
    font-weight: 500;

    svg {
      margin-right: 0.35rem;
      margin-top: -3px;
    }
  }

  .card-course-status {
    font-size: 12px;
  }

  .time-unit {
    padding: 0.25rem 0.5rem 0.1rem 0;
    text-align: center;

    h6 {
      font-size: 13px;
      font-weight: 600;
      position: relative;
      margin-bottom: -0.4rem;
      letter-spacing: 1px;

      &:after {
        &:after {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: $separator-color;
          right: -4px;
          top: 0;
          opacity: 0.7;
        }
      }

      span {
        small {
          font-size: 8px;
          padding-top: 2px;
        }
      }

      &:last-child {
        h6 {
          &:after {
            display: none;
          }
        }
      }

      .afterSale {
        font-size: 16px;
      }

      .beforeSale {
        small {
          font-size: 11px;
          text-decoration: line-through;
        }
      }
    }
  }
}

.topInstructors {
  margin: 1rem auto 0.25rem;

  .instructorCards {
    a {
      text-decoration: none;

      .card {
        .card-header {
          border-bottom: none;
          background-color: transparent;
          padding: 0;

          .bg-image {
            width: 100%;
            // height: 125px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 110px;
              height: 110px;
              object-fit: cover;
              border-radius: 50%;
              transition: all 0.25s ease-in;
              margin: 1rem auto auto;
            }
          }
        }

        .card-body {
          .card-title {
            font-size: 16px !important;
          }

          .card-text {
            color: $title-color;
            font-weight: 300;
            font-size: 14px;
          }
        }

        .card-footer {
          background-color: transparent;
          border-top: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 11px;

          svg {
            margin-right: 0.25rem;
            fill: $desc-color;
          }
        }
      }

      &:hover {
        .bg-image {
          img {
            transform: scale(1.05) translateY(-3px);
          }
        }
      }
    }
  }
}

.swiper-lazy {
  display: none;
}

.swiper-lazy.swiper-lazy-loaded {
  display: block;
}

.swiper-lazy-preloader.scoola-swiper {
  animation: none !important;
  width: 100%;
  height: 139px;
  object-fit: contain;
  transform-origin: 0;
  box-sizing: border-box;
  border: 4px solid transparent;
  border-radius: 0;
  border-top-color: transparent;
  // position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin-left: 0;
  margin-top: 0;
  background-color: transparent;
  border-width: 0;

  .skeleton-wrapper {
    padding: 3px 15px;
  }
}

.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader,
.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader {
  animation: swiper-preloader-spin 1s infinite linear;
}

.gift-btn {
  width: 35px;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #d6d1e5d6;
  opacity: 1;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.add-to-cart-btn {
  position: absolute;
  bottom: -3px;
  right: 0;
  border-radius: 0px !important;
  width: 146px;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  opacity: 0;
  bottom: -40px;
  transition: all 0.15s ease-in;
  font-size: 15px;
  font-weight: 300;
}

.live-icon {
  position: absolute;
  width: 100%;
  top: 15px;
  padding: 0 15px;

  img {
    width: 50px;
    height: 20px;
  }
}

.time-unit {
  padding: 0.25rem 0 0 0.5rem;
  text-align: center;

  h6 {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-bottom: -12px;
    letter-spacing: 1px;
    color: #7e59d1;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: $separator-color;
      right: -4px;
      top: 0;
      opacity: 0.7;
    }
  }

  span {
    small {
      font-size: 8px;
      padding-top: 2px;
    }
  }

  &:last-child {
    h6 {
      &:after {
        display: none;
      }
    }
  }
}

.course-level-container {
  display: flex;
  margin: 7px auto 5px;
}

.course-level {
  display: block;
  margin-top: 3px;
  color: #6a6f73;
  font-size: 13px;
  padding-left: 3.88px;
}

// .duration-icon {
//   margin-left: 57px;
// }

.ZoomeMeetingHomeCard {
  border: none;
  box-shadow: 0px 0px 6px #00000029;
  padding: 2.1rem 3.5rem 2.1rem 2.5rem;
  border-radius: 2px;
  margin-bottom: 60px;

  .webinarContainer {
    align-items: center;

    .webinarImg {
      position: relative;
      margin-bottom: 0;

      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
        box-shadow: 0px 3px 6px #00000019;
      }

      span {
        background-color: #fff;
        border-radius: 15px;
        padding: 3px 12px;
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        top: 20px;
        left: 30px;

        &.happeningNow {
          background-color: $primary-color;
          color: #fff;
        }

        &.comingSoon {
          background-color: $trinary-color;
          color: #fff;
        }

        &.completed {
          background-color: $secondary-color;
          color: #fff;
        }
      }
    }

    .webinarBody {
      h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 28px;
      }

      p {
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .webinarDetails {
        margin-bottom: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-right: 0.8rem;
        }

        span {
          font-size: 14px;
          display: block;
          font-weight: 600;

          &.text-muted {
            margin-bottom: 0.1rem;
            font-weight: 500;
          }
        }
      }

      .webinarJoin {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @include breakpoint(max-xl) {
    padding: 1.1rem 3rem 1.1rem 2rem;

    .webinarContainer {
      .webinarBody {
        h4 {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.userCard-headline {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
}

.userCard-body-details-title {
  font-size: 18px;
  font-weight: 600;

  a {
    color: black !important;
  }
}

.userCard-body {
  display: flex;
  padding-bottom: 50px;
  align-items: center;
}

.userCard-body-image {
  padding-right: 16px;

  img,
  svg {
    border-radius: 8px;
    // border: 1px solid #eee;
    border: 0;
    width: 200px !important;
    height: 200px;
    // object-fit: cover;
  }

  @include breakpoint(max-xl) {
    svg,
    img {
      width: 100px;
      height: 100px;
    }
  }
}

.userCard-body-details-header {
  font-size: 16px;
  font-weight: 400;
  color: $desc-color;
  margin-bottom: 8px;
}

.userCard-body-details-extra {
  font-size: 14px;
  padding-top: 11px;
  padding-bottom: 15px;
  line-height: 1.3;
}

.userCard-body-details-rating {
  padding-top: 13px;

  .userCard-body-details-rating-number {
    color: $star-color;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
    margin-right: 3px;
  }

  @include breakpoint(max-xl) {
    padding-top: 8px;
  }
}

.cart-item-card {
  display: flex;
  justify-content: space-between;

  border-bottom: 0.15rem solid $hover-color;
  padding-bottom: 16px;
  margin-bottom: 16px;
  .cart-item-content {
    display: flex;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .cart-item-img {
    width: 150px;
    height: 100px;
    border-radius: 4px;
    a {
      width: 100%;
      height: 100%;
    }
    img {
      transition: all 0.25s ease-in;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: contain;

      // &:hover {
      //   transform: scale(1.05);
      // }
    }
  }

  div.emptyCart {
    color: #6a6f73;
    font-size: 14px;
    padding: 20px;
  }
}

.cart-item-details {
  padding: 0 35px 0 15px;

  h4 {
    font-size: 16px;
    margin-bottom: 4px;
    color: #2a2a2a;
    font-weight: 500;
    line-height: 1.5;
    a {
      font-size: 16px;
      color: #2a2a2a;
      font-weight: 500;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 4px;
    color: #2a2a2a;
  }

  // span {
  //   margin-right: 2rem;
  //   font-size: 95%;
  //   cursor: pointer;
  //   transition: all 0.25s ease-in;
  //   // display: flex;
  //   // border-bottom: 0.15rem solid $hover-color;
  //   padding-bottom: 20px;
  //   margin-bottom: 20px;

  //   &:last-child {
  //     border-bottom: none;
  //     padding-bottom: 0;
  //     margin-bottom: 0;
  //   }

  //   .cart-item-img {
  //     width: 20%;
  //     overflow: hidden;
  //     border-radius: 4px;

  //     img {
  //       transition: all 0.25s ease-in;
  //       width: 150px;
  //       height: 100px;
  //       object-fit: cover;
  //     }
  //   }

  //   svg {
  //     margin-right: 0.15rem;

  //     path {
  //       transition: all 0.25s ease-in;

  //       .cart-item-details {
  //         padding: 0 35px 0 15px;
  //         width: 80%;

  //         h4 {
  //           font-size: 16px;
  //           font-weight: 500;
  //         }
  //       }
  //     }

  //     position: relative;
  //     // margin-bottom: 2rem;
  //   }
  // }
}

.cart-item-price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .item-price-group {
    text-align: right;
  }
  .cart-item--time {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efeff6;
    border-radius: 2px;
    color: #2a2a2a;
    padding: 4px 10px;
    font-size: 14px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: #2a2a2a;
    margin-bottom: 4px;
    p {
      font-size: 14px;
    }

    span {
      margin-right: 2rem;
      font-size: 95%;
      padding-left: 5px;
      cursor: pointer;
      transition: all 0.25s ease-in;

      &:hover {
        color: $theme-color;

        svg {
          path {
            fill: $theme-color;
          }
        }
      }

      svg {
        margin-right: 0.15rem;

        path {
          transition: all 0.25s ease-in;
        }
      }
    }
  }

  small {
    font-size: 12px;
    text-align: right;
    display: block;
    text-decoration: line-through;
  }
}

.cart-item-delete {
  padding-top: 8px;
  button {
    display: flex;
    align-items: center;
  }
}

.popularCard {
  .popularCard-img {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 4px 4px 0 0;
    }
  }

  .popularCard-details {
    position: absolute;
    bottom: 0px;
    background-color: rgba($color: $background-color, $alpha: 0.92);
    padding: 12px 15px;
    margin-right: 13px;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        h6 {
          text-decoration: underline;
        }
      }
    }

    h6 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @include breakpoint(max-xl) {
    .popularCard-details {
      h6 {
        font-size: 15px;
        line-height: 20px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}

.recentCard {
  margin-bottom: 2rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;

  .recentCard-img {
    img {
      border-radius: 8px 8px 0 0;
      width: 100%;
    }
  }

  .recentCard-details {
    padding: 15px;

    a {
      text-decoration: none;

      p {
        margin-top: 0.5rem;
        font-size: 13px;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  h5 {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
  }

  span {
    small {
      font-size: 12px;
      line-height: 19px;
      color: $desc-color;
    }
  }

  p {
    margin-top: 0.5rem;
    font-size: 13px;
  }
}
