$theme-color: #7e59d1;
$theme-hover-color: #4b20d0;
$primary-color: #cc1915;
$secondary-color: #2da381;
$trinary-color: #f2b636;
$grey-color: #f3f3f4;
$title-color: #2a2a2a;
$desc-color: #6a6f73;
$separator-color: #909193;
$hover-color: #ececec;
$border-color: #cecece;
$background-color: #efeff6;
$bg-hover-color:#E0E0F5;
$background-color2: #382464;
$star-color: #f2b636;
$notification-color: #faf8fd;
$body-color:#212529;
$hover-dangerous-color: #d63d3a;
$divider-line-color: #E0E0F5;
//dashboard var

$header-height: 72px;
$header-bg-base: #fff;
$header-box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
$side-nav-width: 280px;
$side-nav-width-laptop: 230px;
$side-nav-folded: 80px;
$side-nav-box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
$top-nav-height: 55px;
$layout-content-gutter: 24px;
$layout-content-gutter-sm: 15px;
$content-height-offset: 72px;
$content-max-width: 1400px;
$page-header-alt-bg: #fff;
$component-background: #fff;
$border-color-base: #e6ebf1;