// @import '../../../../../sass/mixins';
// @import '../../../../../sass/var';

// .QAndAModal,
// .RequestAppointment,
// .yallaOnlineModal {
//   &.modal {
//     .modal-dialog {
//       @include breakpoint(lg) {
//         width: 525px;
//       }

//       .modal-content {
//         padding: 0.25rem 0.25rem 2rem;
//         border-radius: 8px;

//         .modal-header {
//           border: none !important;
//         }

//         .modal-body {
//           padding: 0 2.5rem;

//           .ant-form {
//             label {
//               font-size: 15px;
//               margin-top: 1.25rem;
//               margin-bottom: 0.5rem;
//             }

//             .ant-form-item-label {
//               &>label {
//                 color: $title-color;
//               }
//             }

//             .ant-input,
//             .ant-picker,
//             .ant-select-selector,
//             .ant-input-number {
//               border: 1px solid $hover-color;
//               padding: 15px;
//               border-radius: 6px;
//               color: $desc-color;
//               width: 100%;
//               height: 45px;

//               &::placeholder {
//                 color: $desc-color;
//               }

//               &:focus-visible {
//                 outline: 1px solid darken($color: $hover-color, $amount: 5%);
//               }

//               &.ant-input-status-error {
//                 border: 1px solid lighten($color: $primary-color, $amount: 25%);
//               }

//               .ant-picker-input {
//                 flex-direction: row-reverse;

//                 .ant-picker-suffix {
//                   margin-right: 10px;
//                 }
//               }
//             }

//             .ant-input-number {
//               padding: 9px 15px;
//             }

//             .ant-input-group.ant-input-group-compact> :last-child {
//               width: 100%;
//             }

//             .ant-upload.ant-upload-select {
//               .ant-btn {
//                 background-color: $theme-color;
//                 color: #fff;
//               }
//             }

//             textarea.ant-input {
//               height: auto;
//             }

//             .ant-form-item-explain {
//               color: $primary-color;
//               margin-top: 0.25rem;
//             }

//             .ant-picker-status-error {
//               border: 1px solid lighten($color: $primary-color, $amount: 25%);
//             }
//           }
//         }

//         .modalSubmit {
//           padding: 9px 26px;
//           text-transform: capitalize;
//           font-size: 16px;

//           &.newMeeting {
//             height: 44px;
//           }
//         }
//       }
//     }
//   }
// }

// #player {
//   min-height: 620px;
//   width: 100%;
//   object-fit: cover;
// }

// .ant-select-dropdown {
//   z-index: 200000;
// }

// .ant-select-selection-item,
// .ant-select-selection-placeholder {
//   line-height: 15px !important;
// }

// .ant-pagination-options-size-changer {
//   display: none !important;
// }

// .ant-checkbox-wrapper {
//   margin-top: 0 !important;
//   margin-bottom: 0 !important;
//   font-size: 14px !important;
// }

// .RequestAppointment.modal,
// .yallaOnlineModal.modal {
//   .modal-dialog {
//     width: 700px;
//   }

//   .dscoola-datepicker {
//     .ant-form-item-label {
//       max-width: 100%;
//     }
//   }
// }

// .notFoundTab {
//   margin-top: 3.5rem;

//   svg {
//     margin-bottom: 1.5rem;
//   }

//   h5 {
//     font-size: 20px;
//     font-weight: 600;
//     line-height: 28px;
//   }

//   p {
//     font-size: 16px;
//     color: $desc-color;
//     line-height: 26px;
//   }
// }

// .ant-row {
//   margin-bottom: 0;

//   &.full-width {
//     display: block;
//   }

//   .ant-form-item-label {
//     text-align: left;
//   }

//   .ant-form-item-control {
//     width: 100%;
//     max-width: 100%;
//   }
// }

// .ant-picker-dropdown {
//   z-index: 10500;
// }

// .textEditor-withRecord {
//   position: relative;

//   .recordMessage {
//     position: absolute;
//     top: 8px;
//     right: 20px;
//   }
// }
.ant-radio-group {
  width: 100% !important;
}
.ant-space {
  width: 100% !important;
}

.RequestAppointment {
  .dateAndTime {
    display: flex;

    h6 {
      margin-top: 1rem;
    }

    .react-calendar {
      max-width: 50%;
      font-family: inherit;
    }
  }
}

.quiz-nav {
  box-shadow: 0px 3px 6px #0000001a;
  width: 100%;
  height: 91px;
}

.quiz-back {
  color: #7e59d1;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 20px;
  cursor: pointer;
}

.quiz-title {
  font-weight: 600;
  font-size: 18px;
}

.quiz-due {
  color: #2a2a2a;
  font-size: 16px;
}

.left-col {
  padding-left: 40px;
}

.quiz-row {
  padding-top: 10px;
}

.quiz-num {
  color: #6a6f73;
  font-size: 17px;

  padding-left: 83px;
  padding-top: 5px;
}

.question-container {
  width: 81%;
  padding-top: 30px;
  margin-left: 116px;
}

.quiz-title-questions {
  font-weight: 600;
  font-size: 22px;
}

.quiz-num-questions {
  color: #6a6f73;
  font-size: 16px;

  padding-top: 5px;
}

.question-title {
  font-size: 20px;
  padding-bottom: 20px;
}

.single-question {
  padding-bottom: 48px;
}

.ant-radio-wrapper {
  color: #6a6f73;
  font-size: 14px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #7e59d1;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #7e59d1;
}

.ant-radio-inner::after {
  background-color: #7e59d1;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7e59d1;
  border-color: #7e59d1;
}

.result-container {
  padding-bottom: 60px;
}

.padd-left {
  padding-left: 10px;
}

.ant-steps {
  padding-bottom: 50px;
  padding-left: 150px;
  padding-right: 150px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #7e59d1;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #7e59d1;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #7e59d1;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #7e59d1;
}

// .anticon svg {
//     margin-bottom: 9px;
// }
.quize-container {
  // background-color:rgba(248 ,248 ,248 ,50%);
  background-color: transparentize(#f8f8f8, 0.5);
}

.question-flex {
  display: flex;
}

.question-numper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #a5a2b4;
  opacity: 0.5;
  padding-right: 17px;
  margin-right: 28px;
  margin-left: 30px;
  position: relative;
}

.question-numper-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: white;
}

.choies-container {
  border: 1px solid #e0e0f5;
  border-radius: 6px;
  // width: 712px;
  // height: 65px;
  padding: 20px;
  padding-top: 21px;
  margin-left: 77px;
}

.choies-container-wrong {
  // border: 1px solid #E0E0F5;
  // border-radius: 6px;
  // width: 712px;
  // height: 65px;
  padding: 20px;
  margin-left: 77px;
  background: #fff1f4;
  position: relative;

  svg {
    position: absolute;
    z-index: 100000;
    left: 20px;
    top: 25px;
    width: 16px;
    height: 16px;
  }
}

.choies-container-correct {
  border: 1px solid #e0e0f5;
  border-radius: 6px;
  // width: 712px;
  // height: 65px;
  // padding-left: 27px;
  // padding-top: 21px;
  padding: 20px;
  margin-left: 77px;
  position: relative;

  svg {
    position: absolute;
    z-index: 100000;
    left: 20px;
    top: 25px;
    width: 16px;
    height: 16px;
  }
}

.actions-btn {
  padding-top: 20px;
  padding-left: 76px;
  padding-right: 28px;
}

.not-correct {
  background: #cc1915 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 16px;
  position: relative;
}

.correct {
  background: #2da381 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 16px;
  position: relative;
}

.answers-count-cyrcle-correct {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #2da381 0% 0% no-repeat padding-box;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  // margin:28px 22px 28px 22px
  margin-right: 22px;
  margin-bottom: 28px;
}

.answers-divider {
  border: 1px solid #e0e0f5;
  height: 78%;
  margin-left: 19px;
  margin-right: 35px;
}

.answers-Legend {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 18px;
}

.answers-count {
  background: #f5f5f5;
  border-radius: 4px;
  width: 208px;
  height: 40px;
  display: flex;
  margin-bottom: 16px;
}

.article {
  height: 577px;
}

.not-answered {
  background: #d2d0d8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 16px;
  position: relative;
}

.answered {
  background: #9575dd 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 16px;
  position: relative;
}

.not-answered-span {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.answered-span {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ans-span {
  font-size: 16px;
  padding-top: 11px;
  padding-left: 10px;
  font-weight: 600;
}

.ques-numbers {
  width: 273px;
  min-height: 154px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0f5;
  border-radius: 8px;
}

.answers-count-cyrcle {
  width: 40px;
  height: 40px;
  background: #7e59d1 0% 0% no-repeat padding-box;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  // margin:28px 22px 28px 22px
  margin-right: 22px;
  margin-bottom: 28px;
}

.answers-count-cyrcle-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.ques-margin {
  margin-left: 22px;
  margin-top: 28px;
}

.answers-count-cyrcle-not-answerd {
  width: 40px;
  height: 40px;
  background: #a5a2b4 0% 0% no-repeat padding-box;
  border-radius: 50%;
  position: relative;
  display: inline-block;

  margin-right: 22px;
  margin-bottom: 28px;
}

.ant-steps {
  display: none;
}

.answers-count-cyrcle-not-correct {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #cc1915 0% 0% no-repeat padding-box;
  border-radius: 50%;
  position: relative;
  display: inline-block;

  margin-right: 22px;
  margin-bottom: 28px;
}

.next-btn {
  width: 84.2px;
}

.prev-btn {
  background: #fff !important;
  border: 1px solid #7e59d1 !important;
  color: #7e59d1;
  &:hover,
  &:focus {
    color: #7e59d1 !important;
  }
}
.swal-button {
  background-color: #7e59d1;
}
.prev-btn:hover {
  background: #fff !important;
}

// .ant-radio-disabled+span {
//   color: #6a6f73;
// }

// .ant-radio.ant-radio-disabled .ant-radio-inner {
//   border-color: #6a6f73;
// }

// .lecture-livesession {
//   width: 100%;
//   height: 400px;
//   background-color: $desc-color;
//   color: #fff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 26px;
// }

// .scoola-video>div {
//   // min-height: 664px;
//   min-height: 470px;
//   background-color: #212121;
// }

// .small-video-preview {
//   .scoola-video>div {
//     // min-height: 664px;
//     min-height: unset;
//     background-color: #212121;
//   }
// }

// // .vjs-playing
// .sticky-vid {
//   .scoola-video>div {
//     height: 300px;
//   }

//   transition: all 0.4s linear;
//   position: fixed;
//   width: 100px;
//   height: 100px;
//   bottom: 30px;
//   right: 30px;
//   height: 230px !important;
//   width: 440px !important;
//   z-index: 10000;

//   &.vjs-fluid:not(.vjs-audio-only-mode) {
//     padding-top: 6.25%;

//     @include breakpoint(max-xl) {
//       width: 300px !important;
//     }
//   }
// }

// .defaultCourePreview {
//   height: 550px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-shadow: 0px 0px 1px 1px #e7e5e5de;
//   margin-bottom: 1rem;

//   img {
//     width: 350px;
//     opacity: 25%;
//   }
// }

.tabs-wrapper {
  // .ant-tabs-content-holder {
  //   margin-top: 16px;
  //   padding: 28px 18px;
  //   border: 1px solid #efeff6;
  //   margin-bottom: 35px;
  // }

  .tab-content-title {
    color: #6a6f73;
    font-size: 24px;
    margin-bottom: 25px;
  }
}

.course-preview-inner {
  padding: 0 0 25px 0;
  // height: 1000vh;
}

#video-presentation-container {
  &.sticky-video-wrapper {
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 25px;
    max-width: 300px;
    right: 130px;
    z-index: 999;

    @media (min-width: 1100px) and (max-width: 1300px) {
      right: 80px;
    }

    @media (max-width: 1100px) {
      right: 30px;
    }
  }
}

.video-presentation-wrapper {
  min-height: 660px;
  padding: 0 0 25px 0;
}

.small-video-preview {
  position: sticky;
  top: 25px;
  padding: 0 20px;
}

.question-wrapper {
  .question-wrapper-title {
    font-size: 16px;
    letter-spacing: 0px;
    color: #2a2a2a;
    margin-bottom: 20px;
  }

  .question-answer-wrapper {
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      letter-spacing: 0px;
      color: #6a6f73;
      margin-bottom: 18px;
    }
  }
}

.question-submit-wrapper {
  display: flex;
  justify-content: flex-end;
}