@import "/src/sass/var";
@import "/src/sass/mixins";

.sideFilter {
  background-color: $background-color;
  padding: 5px 7px 20px;
  border-radius: 8px;
  margin-bottom: 3rem;

  ul {
    list-style: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 0.75rem;
      text-transform: capitalize;

      &.beginner {
        order: 1;
      }

      &.intermediate {
        order: 2;
      }

      &.advanced {
        order: 3;
      }

      @for $i from 5 through 0 {
        &.rating#{$i} {
          order: (5 - $i);
        }
      }

      &.duration- {
        &0-3 {
          order: 1
        }

        &3-6 {
          order: 2
        }

        &6-16 {
          order: 3
        }

        &16Plus {
          order: 4
        }
      }





      .ant-checkbox-wrapper {
        color: $desc-color;
        font-size: 13px !important;

        .css-ryrseu-MuiRating-root {
          font-size: 1.1rem;
          vertical-align: sub;
        }
      }

      .ant-checkbox-wrapper {
        .ant-checkbox+span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          width: 175px;
        }
      }
    }
  }

  .ant-collapse {
    background-color: transparent !important;
    position: relative;
    margin: 1.5rem auto;

    &:after {
      position: absolute;
      content: "";
      bottom: -10px;
      left: 5%;
      width: 90%;
      height: 2px;
      background-color: lighten($color: $desc-color, $amount: 45%);
    }

    &:last-child {
      margin-bottom: 1rem;

      &:after {
        content: unset;
      }
    }

    .ant-collapse-item,
    .ant-collapse-header,
    .ant-collapse-content {
      background-color: transparent !important;
      margin-bottom: 0;
      border: none;
    }

    .ant-collapse-header {
      font-size: 17px;
      padding-bottom: 0;
      padding-top: 0;

      &>div {
        position: absolute;
        right: 0;
        top: 0;

        .ant-collapse-arrow {
          background-color: transparent;

          svg {
            color: inherit;
          }
        }
      }
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-bottom: 0;
      }
    }
  }
}