.input-holder {
  margin-bottom: 16px;

  .ant-form-item-label {
    &.ant-form-item {
      margin-top: 0;
    }

    ::after {
      display: none;
    }

    label {
      font-size: 16px;
      font-weight: 500 !important;
      color: #2a2a2a;
      margin: 0;
    }
  }

  .ant-form-item-control-input-content {
    input {
      height: 45px;
      border-radius: 4px;
      background: #ffffff;
      color: #6a6f73;
      box-shadow: none;
      border-color: #e0e0f5;

      &:disabled {
        background: #f5f5f5;
      }
    }

    .ant-input-affix-wrapper {
      height: 45px;
      border-radius: 4px;
      background: #ffffff;
      color: #6a6f73;
      min-height: 2em;
      width: 100%;
      box-shadow: none;

      input {
        height: 100%;
      }

      &:lang(ar) {
        // direction: ltr !important;
        text-align: right;
        padding-right: 0.5rem;
      }
    }
  }

  label::before {
    display: none !important;
  }

  .flag-dropdown {
    &:lang(ar) {
      left: 0;
    }
  }

  .ant-input-number {
    width: 100%;
    border-radius: 4px;
    box-shadow: none;
  }

  .ant-picker {
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
    height: 45px;

    input {
      height: 100%;
      border-radius: 4px;
      background: #ffffff;
      color: #6a6f73;
    }
  }

  .ant-picker-suffix,
  .ant-picker-clear {
    font-size: 22px;
  }

  .ant-select {
    display: flex;
    justify-content: center;
    height: 45px;
    align-items: center;
    border: 0;

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      width: 100%;
      height: 45px !important;
      border: 1px solid #e0e0f5;
      border-radius: 4px;
      background: #ffffff;
      color: #6a6f73;
      box-shadow: none !important;

      .ant-select-selection-item,
      .ant-select-selection-search {
        line-height: 40px !important;

        input {
          height: 100% !important;
        }
      }
    }
  }

  .PhoneInput {
    margin-bottom: 0.5rem;
    border: 1px solid #e0e0f5;
    border-radius: 4px;
    padding: 4px 11px;
    height: 45px;

    &.PhoneInput--disabled {
      background: #f5f5f5;
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }
    }

    &.PhoneInput--focus {
      box-shadow: none !important;

      input {
        border: 0 !important;
        outline: 0 !important;
      }
    }

    .ant-input-prefix {
      margin: 1rem 0;
      margin-right: 2rem;
      margin-left: 1rem;
      font-size: 2rem;

      img {
        object-fit: contain;
      }
    }

    input {
      letter-spacing: 0.14px;
      color: #6a6f73;
      font-size: 16px;
      border: 0;
      height: 100%;
      padding: 0;
    }
  }

  textarea.ant-input {
    height: 45px;
    border-radius: 4px;
    background: #ffffff;
    color: #6a6f73;
    box-shadow: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0;
    border-style: solid;
    border-radius: 4px;
    background-color: #fff;
  }

  .ant-picker.ant-picker-disabled {
    border-color: transparent;
  }
}

.ant-input-number-handler-wrap {
  // display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-checkbox-inner,
.ant-checkbox {
  border-radius: 4px;

  &:after {
    border-radius: 4px;
  }
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-checkbox-wrapper {
  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 3px;
    }
  }
}
