@import '/src/sass/var';
@import '/src/sass/mixins';

.planningSolutions-item {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  transition: all 0.25s ease-in;

  svg {
    margin-bottom: 1.75rem;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $desc-color;
  }

  text-decoration: none;
}

a.planningSolutions-item:hover {
  box-shadow: 0px 3px 6px #00000059;
}

.contactUsDetails {
  .contactInfo {
    position: relative;

    .birdMessage-svg {
      position: absolute;
      bottom: 100px;
      right: -70px;
    }

    h4 {
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 1.1rem;
    }

    p {
      font-size: 16px;
      margin-bottom: 1.5rem;
    }

    .contactList {
      list-style: none;
      margin-bottom: 3rem;

      .contactItem {
        margin-bottom: 1.5rem;
        font-size: 18px;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .socialMediaList {
      list-style: none;

      li {
        display: inline-block;
        margin-right: 1.1rem;

        svg {
          width: 33px;
          height: 33px;
          transition: all 0.25s ease-in;

          &:hover {
            transform: translateY(-2px);
          }
        }
      }
    }
  }

  .contactForm {
    box-shadow: 0px 3px 6px #00000029;
    padding: 30px 35px;
    margin-top: -130px;
    background: #fff;
    width: 485px;
    margin-bottom: 2.75rem;

    h3 {
      font-size: 24px;
      font-weight: 600;
    }

    form {
      .ant-form-item {
        display: block;
      }

      .ant-form-item-label {
        margin-top: 1rem;
        font-size: 15px;
        font-weight: 500;

        & > label {
          &:after {
            display: none;
          }

          &:before {
            display: none;
          }
        }
      }

      .ant-form-item-control {
        width: 100%;
        border-color: $background-color;

        input {
          height: 45px;
          border-radius: 4px;
          color: $desc-color;
        }

        textarea {
          height: 100px;
          border-radius: 4px;
          color: $desc-color;
          resize: none;
        }
      }
    }
  }

  @include breakpoint(max-xl) {
    .contactInfo {
      margin-right: 2rem;

      .birdMessage-svg {
        bottom: 20px;
        right: 20px;

        svg {
          width: 200px;
        }
      }

      h4 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }

      .contactList {
        margin-bottom: 2rem;

        .contactItem {
          font-size: 16px;
          margin-bottom: 0.75rem;

          svg {
            width: 18px;
          }
        }
      }

      .socialMediaList {
        .socialMediaItem {
          svg {
            width: 30px;
          }
        }
      }
    }

    .contactForm {
      width: 410px;

      h3 {
        font-size: 22px;
      }
    }
  }
}

.blogsPage {
  .breadcrumbs {
    padding-bottom: 0;

    .Breadcrumb-body {
      max-width: 50%;

      p {
        line-height: 30px;
      }
    }

    .landingPageHero {
      margin-top: -60px;
    }
  }

  .blogTabs {
    margin-top: -3rem;
    position: relative;

    .ant-input-search {
      position: absolute;
      top: 66px;
      right: 0px;
      background-color: $background-color;
      padding: 8px 20px;
      width: 100%;

      @media screen and (min-width: 1500px) {
        top: 15px;
        right: 200px;
        background-color: transparent;
        padding: 0;
        width: 180px;
      }

      .ant-input-wrapper {
        .ant-input {
          height: 38px;
          border: 1px solid #fff !important;
          outline: none !important;

          &:focus,
          &:hover {
            box-shadow: none;
          }
        }

        .ant-input-search-button {
          border: none;
          margin-right: 8px;

          .anticon-search {
            svg {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
    }

    .ant-tabs-nav {
      background-color: $background-color2;
      height: 66px;
      color: #fff;

      .ant-tabs-nav-wrap {
        max-width: 1200px;
        margin: auto;

        @include breakpoint(max-xl) {
          margin-left: 2rem;
        }

        .ant-tabs-tab {
          font-size: 17px;
          text-transform: capitalize;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #fff;
            }
          }

          & + .ant-tabs-tab {
            margin: 0 0 0 27px;
          }
        }

        .ant-tabs-ink-bar-animated {
          background-color: #fff;
          bottom: 10px;
        }
      }
    }

    .ant-tabs-content-top {
      max-width: 1200px;
      margin: auto;
      margin-top: 5.5rem;

      .ant-tabs-tabpane {
        .blogArticles {
          margin-bottom: 3rem;
          // margin-top: 3rem;

          & > div > h4 {
            margin-bottom: 1.75rem;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.singleBlog {
  margin-bottom: 3rem;

  .shareToSocialMedia {
    svg {
      width: 30px;
      height: 30px;
      margin-right: 0.75rem;
    }
  }

  .articleHero {
    margin-bottom: 2.25rem;
  }

  .articleBody {
    p {
      line-height: 28px;
    }
  }
}

.careersPage {
  .breadcrumbs {
    padding-bottom: 0;

    h2,
    p {
      width: 70%;
    }

    .Breadcrumb-body {
      max-width: 40%;
    }

    .careersPage-img {
      margin-top: -60px;
    }

    @include breakpoint(max-xl) {
      h2,
      p {
        width: 100%;
      }

      h2 {
        font-size: 28px;
      }

      .careersPage-img {
        width: 390px;
      }
    }
  }

  .careersPage-body {
    .planningSolutions {
      text-align: center;
      margin-bottom: 6rem;
      margin-top: 6rem;

      & > h3 {
        font-size: 32px;
        line-height: 30px;
        margin-bottom: 1.25rem;
      }

      & > p {
        font-size: 18px;
        color: $desc-color;
        margin-bottom: 2rem;
      }
    }

    .coreValues {
      text-align: center;
      margin-bottom: 7rem;

      .coreValues-title {
        margin-bottom: 2rem;

        h3 {
          font-size: 32px;
          margin-bottom: 3.5rem;
          margin-top: 0.75rem;
        }
      }

      .coreValues-body {
        .coreValues-item {
          box-shadow: 0px 1px 6px #00000029;
          border: 4px solid rgba($theme-color, 0.2);
          // opacity: 0.2;
          border-radius: 8px;
          display: flex;
          height: 195px;

          .coreValues-firstBG {
            background-color: rgba($theme-color, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 110px;
          }

          h2 {
            font-size: 20px;
            line-height: 41px;
            font-weight: 600;
            color: $background-color2;
            margin: 17px 0;
            display: flex;
            align-items: center;
          }
        }
      }

      @include breakpoint(max-xl) {
        margin-bottom: 4rem;

        .coreValues-title {
          h3 {
            font-size: 28px;
            margin-bottom: 2rem;
          }
        }

        .coreValues-body {
          & > div {
            margin-bottom: 2rem;

            .coreValues-item {
              .coreValues-firstBG {
                width: 35%;
              }

              h2 {
                width: 65%;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .perksBenefits {
      margin-bottom: 4rem;

      h3 {
        margin-bottom: 3rem;
        font-size: 32px;
        text-align: center;
      }

      .benefits-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3rem;

        svg {
          margin-right: 0.8rem;
          min-width: 24px;
          min-height: 24px;
        }

        h4 {
          color: $theme-color;
          font-size: 24px;
          font-weight: 600;
        }

        p {
          font-size: 18px;
          line-height: 38px;
        }
      }

      @include breakpoint(max-xl) {
        & > h3 {
          font-size: 28px;
          margin-bottom: 2rem;
        }

        .benefits-item {
          svg {
            min-width: 18px;
            min-height: 18px;
          }

          h4 {
            font-size: 22px;
            font-weight: 500;
          }

          p {
            font-size: 16px;
            line-height: 33px;
          }
        }
      }
    }

    @include breakpoint(max-xl) {
      .planningSolutions {
        & > h3 {
          font-size: 28px;
          margin-bottom: 0.75rem;
        }

        & > p {
          font-size: 16px;
        }

        .planningSolutions-item {
          // min-height: 280px;

          &:nth-child(4) {
            display: none;
          }

          h4 {
            font-size: 18px;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
          }

          p {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.aboutUsPage {
  .aboutHero {
    background-color: $background-color;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .aboutHero-left {
      min-width: 58%;
      display: flex;
      justify-content: center;
      margin-left: 0.85rem;

      span.explore {
        font-size: 23px;
        font-weight: 500;
      }

      h2 {
        font-size: 44px;
        line-height: 64px;
        font-weight: 500;
        color: $background-color2;
        margin-bottom: 1.5rem;
      }

      p {
        color: $background-color2;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 2.25rem;
      }

      button {
        font-size: 20px;

        &.greyButton {
          text-transform: capitalize;
          padding: 10px 15px;
          margin-left: 1.5rem;
          border-radius: 4px;
          border: 1px solid $theme-color;
        }
      }
    }

    .aboutHero-right {
      img {
        max-width: 100%;
      }
    }
  }

  .aboutFetureLearning {
    margin-top: 7rem;
    // min-height: 700px;
    max-height: 700px;
    position: relative;
    margin-bottom: 3rem;

    .featureLearning-details {
      span {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
      }

      h3 {
        margin-top: 1rem;
        font-size: 36px;
        line-height: 50px;
        font-weight: 400;
        letter-spacing: -1px;
        margin-bottom: 0.75rem;
      }

      p {
        line-height: 28px;
        margin-bottom: 2.5rem;
        font-size: 16px;
      }
    }

    .ourFeatures {
      & > svg {
        width: 100%;
        position: absolute;
        top: 40px;
        left: -110px;
        z-index: -1;

        @media screen and (max-width: 991px) {
          display: none;
        }

        @include breakpoint(max-xxl) {
          width: 1000px;
          left: -56px;
        }

        @include breakpoint(max-xl) {
          left: -42px;
          width: 800px;
        }
      }

      .featureItem {
        width: 340px;
        position: relative;

        @media screen and (max-width: 991px) {
          position: unset;
          margin-top: 2rem;
        }

        h4 {
          font-size: 20px;
          font-weight: 500;
        }

        p {
          line-height: 30px;
          color: $desc-color;
          font-size: 16px;
        }

        @include breakpoint(max-xl) {
          width: 220px;

          h4 {
            font-size: 18px;
          }

          p {
            line-height: 28px;
            font-size: 15px;
          }
        }
      }

      .feature1 {
        // bottom: 40px;
        bottom: 80px;
        bottom: -220px;
        left: 11.5%;
      }

      .feature2 {
        bottom: 150px;
        bottom: 42px;
        left: 49%;
      }

      .feature3 {
        bottom: 370px;
        left: 71.75%;
        bottom: 410px;
        left: 75.75%;
      }

      .featContainer {
        position: relative;

        svg {
          position: absolute;
          right: 50px;
          top: -110px;
        }
      }

      @include breakpoint(max-xxl) {
        .feature1 {
          bottom: -200px;
          left: 10.5%;
        }

        .feature2 {
          bottom: 42px;
          left: 45.4%;
        }

        .feature3 {
          bottom: 400px;
          left: 70.25%;
        }
      }

      @include breakpoint(max-xl) {
        .featContainer {
          position: relative;

          svg {
            position: absolute;
            right: 10px;
            top: -110px;
          }
        }

        .feature1 {
          bottom: -172px;
          left: 11.2%;
        }

        .feature2 {
          bottom: 80px;
          left: 50%;
        }

        .feature3 {
          bottom: 430px;
          left: 77.5%;
        }
      }
    }

    @include breakpoint(max-xl) {
      max-height: 650px;
    }
  }

  .weOffer {
    // max-width: 1300px;
    margin: auto;
    // padding: 0 15px;
    margin-bottom: 4rem;

    .weOffer-img {
      width: 100%;
    }

    p.trinary-color {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 500;
    }

    h2 {
      font-size: 36px;
      letter-spacing: -1px;
      margin-bottom: 1.5rem;
    }

    .weOffer-details {
      .ant-col {
        color: $background-color2;
        font-size: 23px;
        font-weight: 500;
        margin-left: -9px;
      }

      svg {
        width: 80px;
        height: 80px;
      }
    }

    @include breakpoint(max-xl) {
      h2 {
        font-size: 36px;
      }

      .weOffer-details {
        svg {
          width: 60px;
          height: 60px;
        }

        .ant-col {
          font-size: 21px;
        }
      }
    }
  }

  .aboutTestimonial {
    margin-top: 6rem;

    .aboutTestimonial-title {
      text-align: center;
      margin-bottom: 3rem;

      p.trinary-color {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
      }

      h4 {
        font-size: 36px;
      }
    }

    .aboutTestimonial-person {
      .aboutTestimonial-img {
        position: relative;

        img {
          width: 90%;

          @include breakpoint(max-xl) {
            width: 100%;
          }
        }

        .dottedPattern {
          position: absolute;
          top: 10px;
          right: 38px;
          z-index: -1;
        }
      }
    }

    .aboutTestimonial-details {
      .readOnlyRating {
        .MuiRating-root {
          font-size: 30px;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      .ratingAverage {
        display: none;
      }

      p {
        font-size: 24px;
        line-height: 38px;
        width: 93%;
      }

      h3 {
        font-size: 20px;
        font-weight: 700;
      }

      @include breakpoint(max-xl) {
        p {
          width: 87%;
        }
      }
    }

    .swiper {
      // width: 100%;
      // height: 100%;
      min-height: 600px;
      width: 1180px;
      padding-left: 8px !important;
      border: 12px solid transparent;

      .swiper-button-next,
      .swiper-button-prev {
        top: 45%;
      }

      @include breakpoint(max-xl) {
        width: 874px;
      }
    }

    .swiper-slide {
      /* Center slide text vertically */
      background: #fff;

      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}

.termsOfUsePage {
  scroll-behavior: smooth !important;

  .termsOfUse-body {
    // max-width: 1200px;
    // margin: auto;
    scroll-behavior: smooth !important;
    // position: relative;

    .toTop {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $title-color;
      border: 1px solid $border-color;
      position: fixed;
      bottom: 40px;
      right: 40px;
      line-height: 40px;
      text-align: center;
      fill: $hover-color;
      cursor: pointer;
      transition: all 0.25s ease-in;

      &:hover {
        background-color: $theme-color;
        // border: 1px solid $title-color;
        fill: $hover-color;
        line-height: 35px;
      }
    }

    .termsOfUse-title {
      margin-top: 1rem;

      .termOfUse-list {
        list-style: none;

        a {
          text-decoration: none;
          color: $title-color;
        }

        li {
          padding: 8px 0 8px 15px;
          font-size: 16px;
          font-weight: 500;
          position: relative;
          margin-bottom: 0.25rem;
          transition: all 0.25s ease-in;

          &:before {
            content: '';
            position: absolute;
            background-color: $theme-color;
            width: 0;
            height: 100%;
            left: 0;
            top: 0;
            // opacity: 0;
            transition: all 0.25s ease-in;
          }

          &.active {
            background-color: $hover-color;
            color: $theme-color;

            &::before {
              // opacity: 1;
              width: 4px;
            }
          }
        }
      }
    }

    .termsOfUse-details {
      scroll-behavior: smooth !important;

      .termsOfUse-block {
        padding-top: 1rem;
        scroll-behavior: smooth !important;

        & + .termsOfUse-block {
          padding-top: 3rem;
        }

        h4 {
          font-size: 24px;
          margin-bottom: 1.35rem;
        }

        p {
          line-height: 26px;
          font-size: 16px;
          color: $desc-color;
          font-weight: 500;
        }
      }

      margin-bottom: 5rem;
    }
  }

  @include breakpoint(max-xl) {
    .termsOfUse-body {
      .termsOfUse-details {
        .termsOfUse-block {
          & + .termsOfUse-block {
            padding-top: 1.5rem;
          }

          h4 {
            font-size: 22px;
            margin-bottom: 0.75rem;
          }

          p {
            font-size: 15px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

.helpSupportPage {
  .breadcrumbs {
    padding-bottom: 0;

    img {
      margin-top: -4.35rem;
    }
  }

  .helpSupport-body {
    // max-width: 1200px;
    // margin: auto;

    .helpSupport-faq {
      margin-bottom: 3rem;

      h3 {
        margin-bottom: 1.5rem;
      }

      .ant-collapse-item {
        padding: 8px 0;

        .ant-collapse-header {
          font-size: 17px;
        }

        .ant-collapse-content-box {
          a {
            text-decoration: none;
            color: $separator-color;
            margin-left: 1.6rem;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .helpSupport-byTopic {
      margin-bottom: 3rem;

      h3 {
        margin-bottom: 2rem;
      }

      .swiper {
        min-height: 300px;
        border: 1px solid transparent;
        padding-left: 20px !important;
        padding-right: 8px !important;
        margin-left: -20px !important;
        justify-content: center;

        @include breakpoint(max-sm) {
          width: 300px;
        }

        @media screen and (min-width: 1024px) {
          width: 874px;
        }

        @media screen and (min-width: 1200px) {
          width: 1180px;
        }

        .swiper-button-next,
        .swiper-button-prev {
          top: 35%;
          height: calc(1.2vw + 20px);
          width: calc(1.2vw + 20px);
        }
      }

      .swiper-slide {
        /* Center slide text vertically */
        background: #fff;

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
    }
  }

  @include breakpoint(max-xl) {
    .breadcrumbs {
      h2 {
        font-size: 28px;
      }
    }

    .helpSupport-body {
      .helpSupport-faq {
        h3 {
          font-size: 23px;
        }

        .ant-collapse-icon-position-start {
          .ant-collapse-header {
            padding: 6px 16px;

            .ant-collapse-header-text {
              font-size: 13px;
              margin-top: 4px;
              font-weight: 600;
            }
          }

          .ant-collapse-content-box {
            p {
              margin-bottom: 0.75rem;

              a {
                font-size: 13px;
              }
            }
          }
        }
      }

      .helpSupport-byTopic {
        margin-bottom: 1rem;

        h3 {
          font-size: 24px;
        }

        .planningSolutions-item {
          h4 {
            font-size: 18px;
            margin-bottom: 0.5rem;
          }

          p {
            font-size: 15px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.supportSingleArticle {
  .supportSingleArticle-body {
    margin-bottom: 5rem;

    p {
      u {
        font-weight: 600;
      }
    }

    ol {
      li {
        margin-bottom: 1rem;
      }
    }
  }
}

.notificationPage {
  margin: 5rem auto 5rem;

  // max-width: 1200px;
  .ant-card {
    box-shadow: 0px 0px 6px #24242429;
    border: none;

    .ant-card-head {
      background-color: $background-color;

      .ant-card-head-title {
        font-weight: 600;
      }

      .ant-card-extra {
        display: flex;
        align-items: center;
        gap: 14px;
        a {
          color: #7e59d1;
          // margin-right: 1rem;
        }
      }
    }

    .ant-card-body {
      padding: 0;
      // padding-bottom: 1.5rem;

      .notification-item {
        padding: 1rem 1.5rem;
        display: flex;
        align-items: flex-start;

        &.newNotification {
          background-color: rgba($theme-color, 0.04);
          color: $body-color;
        }

        & > img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 1rem;
          border-radius: 50%;
          background-color: #fff;
        }

        p {
          margin-bottom: 0.25rem;
          font-size: 16px;
          font-weight: 500;
        }

        svg {
          margin-right: 0.35rem;
        }
      }
    }
  }

  @include breakpoint(max-xl) {
    .ant-card {
      .ant-card-body {
        .notification-item {
          p {
            font-size: 15px;
          }
        }

        .notification-item {
          padding: 1rem 1.5rem 0.5rem;
        }
      }
    }
  }
}
