.countdown-timer {
    width: 600px;
    height: 100px;
    border: 2px solid black;

    font-family: "Oswald";
    font-size: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-timer span {
    margin-left: 5px;
    margin-right: 5px;
}

.two-numbers {
    width: 2ch;
}